<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between mob-mb-15">
      <div class="col-12 col-md-auto">
        <h1>
          {{ $t("dashboard.title") }}
        </h1>
      </div>
    </div>
    <div class="row"></div>

    <div class="c-table mb-3">
      <vue-good-table
        styleClass="table vgt-table sortEnable tablesorter tablesorter-bootstrap"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span class="cursor-pointer">
            {{ formatLabel(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <div class="country" v-if="props.column.field === checkCountry">
            <gb-flag width="24px" height="16px" :code="props.row.iso" />
            <span class="flag-text">
              {{
                $i18n.locale === "ru"
                  ? props.row.country_name_ru
                  : props.row.country_name_en
              }}
            </span>
          </div>
          <div class="country" v-if="props.column.field === 'avg_price_mob'">
            <b>$ {{ props.row.avg_price_mob }}</b>
          </div>
          <div class="country" v-if="props.column.field === 'avg_price_pc'">
            <b>$ {{ props.row.avg_price_pc }}</b>
          </div>
          <div
            class="country"
            v-if="props.column.field === 'avg_profit_per_thousand_mob'"
          >
            <b>$ {{ props.row.avg_profit_per_thousand_mob }}</b>
          </div>
          <div
            class="country"
            v-if="props.column.field === 'avg_profit_per_thousand_pc'"
          >
            <b>$ {{ props.row.avg_profit_per_thousand_pc }}</b>
          </div>
        </template>
        <div slot="emptystate">
          {{ $t("any_results") }}
        </div>
      </vue-good-table>
    </div>
    <div class="text-center">
      <router-link to="/top">
        <button class="btn">
          {{ $t("show_all") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DashboardService from "@/api/dashboardService";
import "@/assets/css/_page-dashboard.scss";

export default {
  name: "Dashboard",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("dashboard.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("dashboard.title")}`;
  },
  data() {
    return {
      periodValue: { title: this.$t("today"), value: "today" },
      filterPeriod: [
        { title: this.$t("today"), value: "today" },
        { title: this.$t("yesterday"), value: "yesterday" },
        { title: this.$t("total"), value: "total" },
      ],
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "40%",
          },
        },
        chart: {
          id: "basic-bar",
          height: 380,
          type: "line",
          width: "20%",
        },
        xaxis: {
          categories: [
            "30.07.22",
            "31.07.22",
            "01.08.22",
            "02.08.22",
            "02.08.22",
            "03.08.22",
            "03.08.22",
            "04.08.22",
            "05.08.22",
          ],
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        fill: {
          colors: ["#1A73E8", "#B32824"],
        },
      },
      series: [
        {
          name: "series-1",
          type: "column",
          data: [30, 40, 45, 50, 49, 60, 70, 91, 32],
          color: "#5E90DB",
        },
        {
          name: "series-2",
          type: "column",
          data: [32, 22, 32, 53, 41, 64, 77, 98, 34],
          color: "#FFC059",
        },
        {
          name: "series-2",
          type: "column",
          data: [32, 22, 32, 53, 41, 64, 77, 98, 55],
          color: "#489C96",
        },
      ],
      rows: [],
      columns: [
        {
          label: "dashboard.table.country",
          field: "country_name",
        },
        {
          label: "dashboard.table.average_price_mob",
          field: "avg_price_mob",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_price_pc",
          field: "avg_price_pc",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_income_mob",
          field: "avg_profit_per_thousand_mob",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_income_pc",
          field: "avg_profit_per_thousand_pc",
          type: "price",
          sortFn: this.sortFn,
        },
      ],
    };
  },
  mounted() {
    this.getTopCountries();
    this.columns[0].field = this.checkCountry;
  },
  watch: {
    "$i18n.locale": function () {
      this.columns[0].field = this.checkCountry;
    },
  },
  computed: {
    checkCountry() {
      return this.$i18n.locale === "ru" ? "country_name_ru" : "country_name_en";
    },
  },
  methods: {
    getTopCountries() {
      setTimeout(() => {
        DashboardService.getTopCounties().then((res) => {
          if (res.status === 200) {
            this.rows = res.data;
          }
        });
      }, 100);
    },
    formatLabel(el) {
      return this.$t(el);
    },
    sortFn(x, y) {
      return Number(x) - Number(y);
    },
    moment: (e) => {
      return moment(e);
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      if (num > 0) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return str.toFixed(3);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-table ::v-deep .table th:nth-child(2) {
  padding-left: 22px;
}

.c-table ::v-deep th.sorting-desc {
  button:after {
    display: none;
  }
}

.c-table ::v-deep th.sorting-asc {
  button:before {
    display: none;
  }
}

.c-table ::v-deep th.sortable button {
  margin-left: -2px;
  &:after,
  &:before {
    left: 0;
  }
}
</style>
